import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep } from 'lodash';

import * as UserActions from './user.actions';
import { User } from '../../shared/models/user.model';

export const userFeatureKey = 'user';

export interface UserState {
  users: Record<string, Array<User>>;
  error: string | null;
}

export const initialState: UserState = {
  users: {},
  error: null,
};

const authReducer = createReducer(
  initialState,
  on(UserActions.getUsers, (state) => {
    return {
      ...state,
      error: null,
    };
  }),
  on(UserActions.getUsersSuccess, (state, data) => {
    const users = cloneDeep(state.users);
    users[data.data.page] = data.data.items;
    return {
      ...state,
      users,
    };
  }),
  on(UserActions.getUsersFailure, (state, data) => {
    let error = 'There was an error';

    if (data.error) {
      if (data.error.error && data.error.error.detail) {
        error = data.error.error.detail;
      } else if (data.error.message) {
        error = data.error.message;
      }
    }
    return {
      ...state,
      error,
    };
  }),
);

export function reducer(state: UserState | undefined, action: Action): UserState {
  return authReducer(state, action);
}
