import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { User } from '../../shared/models/user.model';

export const authFeatureKey = 'auth';

export interface AuthState {
  currentUser: User | null;
  token: string | null;
  exp: number | null;
  error: string | null;
}

export const initialState: AuthState = {
  currentUser: null,
  token: null,
  exp: null,
  error: null,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.setCurrentUser, (state, data) => {
    const { currentUser } = data;
    return {
      ...state,
      currentUser,
    };
  }),
  on(AuthActions.setToken, (state, data) => {
    return {
      ...state,
      token: data.idToken,
      exp: data.exp,
    };
  }),
  on(AuthActions.clearAuthData, (state) => {
    return {
      ...state,
      currentUser: null,
      token: null,
      exp: null,
      error: null,
    };
  }),
  on(AuthActions.login, (state, data) => {
    return {
      ...state,
      token: data.idToken,
      exp: data.exp,
      error: null,
    };
  }),
  on(AuthActions.loginSuccess, (state, data) => {
    return {
      ...state,
      currentUser: data.user,
    };
  }),
  on(AuthActions.loginFailure, (state, data) => {
    let error = 'There was an error';

    if (data.error) {
      if (data.error.error && data.error.error.detail) {
        error = data.error.error.detail;
      } else if (data.error.message) {
        error = data.error.message;
      }
    }
    return {
      ...state,
      error,
    };
  }),
  on(AuthActions.logout, (state) => {
    return {
      ...state,
      token: null,
      exp: null,
      currentUser: null,
      error: null,
    };
  }),
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
