import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '../../../shared/models/user.model';
import * as fromApp from '../../../store';
import { logout } from '../../../store/auth/auth.actions';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

@Component({
  selector: 'cdl-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  userMenuItems:MenuItem[] = [];
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  loggedIn = false;
  currentUser:User|null = null;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.store.select(selectCurrentUser)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((currentUser:User|null) => {
        this.currentUser = currentUser;
        this.loggedIn = this.currentUser !== null;
        this.userMenuItems = [{
          label: 'Logout',
          icon: 'pi pi-fw pi-sign-out',
          command: () => this.logout(),
        }];
        if (currentUser) {
          if (currentUser.isAdmin) {
            this.userMenuItems.unshift(
              {
                label: 'Users',
                icon: 'pi pi-fw pi-users',
                command: () => this.router.navigate(['/admin/users'])
              }
            );
          } else {
            this.userMenuItems.unshift(
              {
                label: 'Profile',
                icon: 'pi pi-fw pi-user',
                command: () => this.router.navigate(['/user/profile'])
              }
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goHome(): void {
    if (this.currentUser && this.currentUser.role) {
      if (this.currentUser.isAdmin) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  logout(): void {
    this.store.dispatch(logout());
  }

}
