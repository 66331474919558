import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { User } from '../../shared/models/user.model';

export const setCurrentUser = createAction(
  'Auth - Set Current User',
  props <{currentUser: User}>(),
);

export const setToken = createAction(
  'Auth - Set Token',
  props <{idToken: string, exp: number | null}>(),
);

export const clearAuthData = createAction(
  'Auth - Clear Auth Data',
);

export const login = createAction(
  'Auth - Login User',
  props <{idToken: string, exp: number}>(),
);

export const loginSuccess = createAction(
  'Auth - Login User Success',
  props <{user: User}>(),
);

export const loginFailure = createAction(
  'Auth - Login User Failure',
  props <{error: HttpErrorResponse}>(),
);

export const logout = createAction(
  'Auth - Logout User',
);
