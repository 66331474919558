// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://services.cdl.proximityr.com/api/v1',
  loginUrl: 'cdl.proximityr.com/login',
  cognito: {
    region: 'us-east-1',
    clientId: '6gjb5i7tk46vpb90rfvdescfph',
    identityPoolId: 'us-east-1:8b133a9e-036a-4a77-87ad-42a1a7e22a56',
    userPoolId: 'us-east-1_SvUMC5fpo'
  },
  powerBI: {
    workspace: '8712678d-c8f0-43b4-988d-187269693d2a'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
