import { AfterViewInit, Component, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AutoComplete } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown'
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { RoleEnum } from '../../../constants/role.enums';
import { UserService } from '../../../core/service/user.service';
import { Campus } from '../../models/campus.model';
import { Corporation } from '../../models/corporation.model';
import { Dealership } from '../../models/dealership.model';
import { NewUser } from '../../models/user.model';

interface RoleItem {
  label: string,
  value: string
}

interface onChangeEvent {
  originalEvent: PointerEvent;
  value: string;
}

interface onCompleteEvent {
  originalEvent: InputEvent;
  query: string;
}

@Component({
  selector: 'cdl-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss']
})
export class NewUserDialogComponent implements AfterViewInit  {

  @ViewChild(Dropdown) roleDropdown:Dropdown | undefined;

  @ViewChildren(AutoComplete) autocompletes:QueryList<AutoComplete> | undefined;

  corporation: AutoComplete | undefined;
  campus: AutoComplete | undefined;
  dealership: AutoComplete | undefined;

  newUser:NewUser | null = null;
  newUserForm = this.formBuilder.group({
    id: this.config.data.user?.id,
    email: [this.config.data.user?.email, [Validators.required, Validators.email]],
    firstName: [this.config.data.user?.firstName, [Validators.required]],
    lastName: [this.config.data.user?.lastName, [Validators.required]],
    role: [this.config.data.user?.role?.name?.toLowerCase(), [Validators.required]],
    corporation_id: null,
    campus_id: null,
    dealership_id: null,
  });

  roles: RoleItem[] = [
    { label: 'Super Admin', value: RoleEnum.Super },
    { label: 'Admin', value: RoleEnum.Admin },
    { label: 'Manager', value: RoleEnum.Manager },
    { label: 'Salesperson', value: RoleEnum.Sales },
  ];

  corporations: Array<Corporation> = [];
  corporationSuggestions: Array<string> = [];
  campuses: Array<Campus> = [];
  campusSuggestions: Array<string> = [];
  dealerships: Array<Dealership> = [];
  dealershipSuggestions: Array<string> = [];
  hideControls = false;
  validCorporation = false;
  validCampus = false;
  validDealership = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    readonly userService: UserService,
    private formBuilder: FormBuilder,
  ) { }

  ngAfterViewInit(): void {
    this.corporation = this.autocompletes?.find((item: AutoComplete) => item.el.nativeElement.id === 'corporation');
    this.campus = this.autocompletes?.find((item: AutoComplete) => item.el.nativeElement.id === 'campus');
    this.dealership = this.autocompletes?.find((item: AutoComplete) => item.el.nativeElement.id === 'dealership');
  }

  close(submit:boolean): void {
    if (submit && !this.newUserForm.invalid) {
      this.newUser = {
        email: this.newUserForm.value.email,
        firstName: this.newUserForm.value.firstName,
        lastName: this.newUserForm.value.lastName,
        role: this.newUserForm.value.role,
        corporationId: this.newUserForm.value.corporation_id,
        campusId: this.newUserForm.value.campus_id,
        dealershipId: this.newUserForm.value.dealership_id,
      };  
    } else if (!submit) {
      this.newUser = null;
    }
    if (!submit || !this.newUserForm.invalid) {
      this.ref.close(this.newUser);
    }
  }

  searchCorporations($event:onCompleteEvent): void {
    this.userService.getCorporations($event.query)
      .subscribe((corporations:Corporation[]) => {
        this.corporations = corporations;
        if (this.corporations.length === 0) {
          this.onCorporationCleared();
        }
        this.corporationSuggestions = corporations.map(c => c.name || '');
      });
  }

  onCorporationChange($event:string): void {
    const selectedCorporation = this.corporations.find(c => c.name === $event)
    const corporationId:number | undefined = selectedCorporation?.id;
    if (corporationId !== this.newUserForm.get('corporation_id')?.value) {
      this.onCampusCleared();
    }
    this.newUserForm.get('corporation_id')?.setValue(corporationId);
    this.onCorporationKeyUp();
  }

  onCorporationKeyUp(): void {
    this.validCorporation = (this.newUserForm.get('corporation_id')?.value !== null) &&
      (this.newUserForm.get('campus_id')?.value === null) &&
      (this.newUserForm.get('dealership_id')?.value === null);
  }

  onCorporationCleared(): void {
    this.corporation?.writeValue('')
    this.newUserForm.get('corporation_id')?.reset();
    this.campus?.writeValue('')
    this.newUserForm.get('campus_id')?.reset();
    this.dealership?.writeValue('')
    this.newUserForm.get('dealership_id')?.reset();
  }

  searchCampuses($event:onCompleteEvent): void {
    this.userService.getCampuses($event.query, this.newUserForm.get('corporation_id')?.value)
      .subscribe((campuses:Campus[]) => {
        this.campuses = campuses;
        if (this.campuses.length === 0) {
          this.onCampusCleared();
        }
        this.campusSuggestions = campuses.map(c => c.name || '');
      });
  }
  
  onCampusChange($event:string): void {
    const selectedCampus = this.campuses.find(c => c.name === $event)
    const campusId:number | undefined = selectedCampus?.id;
    if (campusId !== this.newUserForm.get('campusId')?.value) {
      this.onDealershipCleared();
    }
    this.newUserForm.get('campus_id')?.setValue(campusId);
    this.onCampusKeyUp();
  }

  onCampusKeyUp(): void {
    this.validCampus = (this.newUserForm.get('corporation_id')?.value !== null) &&
      (this.newUserForm.get('campus_id')?.value !== null) &&
      (this.newUserForm.get('dealership_id')?.value === null);
  }
  
  onCampusCleared(): void {
    this.campus?.writeValue('')
    this.newUserForm.get('campus_id')?.reset();
    this.dealership?.writeValue('')
    this.newUserForm.get('dealership_id')?.reset();
  }
  
  searchDealerships($event:onCompleteEvent): void {
    this.userService.getDealerships($event.query, this.newUserForm.get('campus_id')?.value)
      .subscribe((dealerships:Dealership[]) => {
        this.dealerships = dealerships;
        if (this.dealerships.length === 0) {
          this.onDealershipCleared();
        }
        this.dealershipSuggestions = dealerships.map(c => c.name || '');
      });
  }

  clearForm(): void {
    this.newUserForm.reset();
    this.roleDropdown?.writeValue('');
    this.onCorporationCleared();
  }

  onDealershipChange($event:string): void {
    const selectedDealership = this.dealerships.find(c => c.name === $event)
    const dealershipId:number | undefined = selectedDealership?.id;
    this.newUserForm.get('dealership_id')?.setValue(dealershipId);
    this.onDealershipKeyUp();
  }

  onDealershipKeyUp(): void {
    this.validDealership = (this.newUserForm.get('corporation_id')?.value !== null) &&
      (this.newUserForm.get('campus_id')?.value !== null) &&
      (this.newUserForm.get('dealership_id')?.value !== null);
  }
  
  onDealershipCleared(): void {
    this.dealership?.writeValue('');
    this.newUserForm.get('dealership_id')?.reset();
  }
  
  onRoleChange($event:onChangeEvent): void {
    this.newUserForm.get('role')?.setValue($event.value);
    this.hideControls = $event.value === 'super';
    if (this.hideControls) {
      this.onCorporationCleared();
    }
  }

  get hasRequired(): boolean {
    return this.hideControls || (this.validCorporation || this.validCampus || this.validDealership);
  }

  get campusDisabled(): boolean {
    return this.newUserForm.get('corporation_id')?.value === null;
  }

  get dealershipDisabled(): boolean {
    return this.newUserForm.get('campus_id')?.value === null;
  }
  
}
