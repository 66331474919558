import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { HomePageComponent } from './core/feature/home/home-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '', redirectTo: '', pathMatch: 'full'
  },
  {
    path: 'login', loadChildren: () =>
    import('./core/feature/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin', loadChildren: () =>
    import('./core/feature/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard', loadChildren: () =>
    import('./core/feature/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
