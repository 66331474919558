import { Base } from './base.model';
import { Dealership, DeserializedDealership, SerializedDealership } from './dealership.model';

export interface SerializedCampus {
  id?: number;
  name?: string;
  dealerships?: SerializedDealership[];
}

export interface DeserializedCampus {
  id?: number;
  name?: string;
  dealerships?: DeserializedDealership[];
}

export class Campus extends Base {

  public id?: number;
  public name?: string;
  public dealerships?: Dealership[];

  constructor(data?: DeserializedCampus) {
    super();

    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.dealerships = data.dealerships ? data.dealerships.map(d => new Dealership(d)) : [];
    }
  }

  static deserialize(campus: SerializedCampus): Campus {
    return new Campus({
      ...campus,
      dealerships: campus.dealerships ? campus.dealerships.map(d => Dealership.deserialize(d)) : [],
    });
  }

  serialize(): SerializedCampus {
    return {
      id: this.id,
      name: this.name,
      dealerships: this.dealerships ? this.dealerships.map(d => d.serialize()) : [],
    };
  }
}