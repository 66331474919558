import { ActionReducer } from '@ngrx/store';
import { merge, pick } from 'lodash';

// tslint:disable-next-line
function setSavedState(state: any, localStorageKeySet: string): void {
  localStorage.setItem(localStorageKeySet, JSON.stringify(state));
}

// tslint:disable-next-line
function getSavedState(localStorageKeyGet: string): any {
  const item = localStorage.getItem(localStorageKeyGet);
  return item ? JSON.parse(item) : null;
}

// the keys from state which we'd like to save.
const stateKeys = ['auth.token', 'auth.exp'];
// the key for the local storage.
export const localStorageKey = '__app_storage__';

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  let onInit = true; // after load/refresh…
  return (state, action) => {
    // reduce the nextState.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(localStorageKey);
      return merge(nextState, savedState);
    }
    // save the next state to the application storage.
    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
}
