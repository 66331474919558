import { Base } from './base.model';

export interface SerializedDealership {
  id?: number;
  name?: string;
}

export interface DeserializedDealership {
  id?: number;
  name?: string;
}

export class Dealership extends Base {

  public id?: number;
  public name?: string;


  constructor(data?: DeserializedDealership) {
    super();

    if (data) {
      this.id = data.id;
      this.name = data.name;
    }
  }

  static deserialize(role: SerializedDealership): Dealership {
    return new Dealership({
      ...role,
    });
  }

  serialize(): SerializedDealership {
    return {
      id: this.id,
      name: this.name,
    };
  }
}