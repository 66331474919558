import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuModule } from 'primeng/menu';

import { AppHeaderComponent } from './components/app-header/app-header.component';
import { NewUserDialogComponent } from './dialogs/new-user-dialog/new-user-dialog.component';

const sharedModules = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const sharedComponents = [
  AppHeaderComponent,
  NewUserDialogComponent,
];

@NgModule({
  declarations: [
    ...sharedComponents,
  ],
  imports: [
    CommonModule,
    ...sharedModules,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    MenuModule,
    DialogModule,
    DynamicDialogModule,
    InputTextModule,
    RippleModule,
    DropdownModule,
    AutoCompleteModule
  ],
  exports: [
    ...sharedModules,
    ...sharedComponents,
  ],
  providers: [
    DialogService
  ]
})
export class SharedModule { }

