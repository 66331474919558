import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as UserActions from './user.actions';
import { AppState } from '../index';
import { UserService } from '../../core/service/user.service';

@Injectable()
export class UserEffects {

  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly userService: UserService,
    readonly store: Store<AppState>
  ) { }

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUsers),
      concatMap(data => {
        return this.userService.getUsers(data.offset, data.limit)
          .pipe(
            map(page => UserActions.getUsersSuccess({ data: page })),
            catchError(error => of(UserActions.getUsersFailure({ error })))
          );
      })
    );
  });

}
