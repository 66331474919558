import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { UserService } from '../core/service/user.service';
import { environment } from '../../environments/environment';
import { User } from '../shared/models/user.model';
import * as fromApp from '../store';
import { AuthState } from '../store/auth/auth.reducer';
import { setCurrentUser } from '../store/auth/auth.actions';
import { selectAuthState } from '../store/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    readonly router: Router,
    readonly store: Store<fromApp.AppState>,
    readonly userService: UserService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      take(1),
      switchMap((authState: AuthState): Observable<{token: string | null, currentUser: User | null, doUpdateState: boolean}> => {
        if (!authState.token) {
          return of({ token: null, currentUser: null, doUpdateState: false});
        } else if (!authState.currentUser) {
          return this.userService.getMe().pipe(
            map( res => ({token: authState.token, currentUser: res, doUpdateState: true}))
          );
        } else {
          return of({token: authState.token, currentUser: authState.currentUser, doUpdateState: false});
        }
      }),
      switchMap( (authInfo: { token: string | null, currentUser: User | null, doUpdateState: boolean }) => {
        if (authInfo.currentUser && authInfo.doUpdateState) {
          const { currentUser } = authInfo;
          this.store.dispatch(setCurrentUser({ currentUser }));
        }
        if (authInfo.token && authInfo.currentUser) {
          // user is logged in
          if ((state.url.startsWith('/admin') && authInfo.currentUser.isAdmin) ||
            (!state.url.startsWith('/admin') && !authInfo.currentUser.isAdmin)) {
              return of(true);
            }
        } else {
          if (state.url.startsWith('/login') || (state.url === '/' && environment.production)) {
            return of(true);
          } else {
            this.router.navigate(['/login']);
            return of(false);
          }
        }
        return of(false);
      })
    );
  }
}
