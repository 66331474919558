import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuth from './auth/auth.reducer';
import * as fromUser from './user/user.reducer';
import { storageMetaReducer } from './storage.metareducer';

export interface AppState {
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromUser.userFeatureKey]: fromUser.UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromAuth.authFeatureKey]: fromAuth.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [
  storageMetaReducer
] : [
  storageMetaReducer
];
