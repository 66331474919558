import { Base } from './base.model';
import { Campus, DeserializedCampus, SerializedCampus } from './campus.model';

export interface SerializedCorporation {
  id?: number;
  name?: string;
  campuses?: SerializedCampus[];
}

export interface DeserializedCorporation {
  id?: number;
  name?: string;
  campuses?: DeserializedCampus[];
}

export class Corporation extends Base {

  public id?: number;
  public name?: string;
  public campuses?: Campus[];

  constructor(data?: DeserializedCorporation) {
    super();

    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.campuses = data.campuses ? data.campuses.map(c => new Campus(c)) : [];
    }
  }

  static deserialize(corporation: SerializedCorporation): Corporation {
    return new Corporation({
      ...corporation,
      campuses: corporation.campuses ? corporation.campuses.map(c => Campus.deserialize(c)) : [],
    });
  }

  serialize(): SerializedCorporation {
    return {
      id: this.id,
      name: this.name,
      campuses: this.campuses ? this.campuses.map(c => c.serialize()) : [],
    };
  }
}