import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from './shared/models/user.model';
import * as fromApp from './store';
import { selectCurrentUser } from './store/auth/auth.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly router: Router,
    readonly store: Store<fromApp.AppState>
  ) { }

  ngOnInit(): void {
    // if the user is logged in send them to the main dashboard page
    this.store.select(selectCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentUser:User|null) => {
        if (currentUser) {
          this.router.navigate([`${currentUser.isAdmin ? '/admin' : '/dashboard'}`]);
        }
      });
  }

}