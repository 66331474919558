import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Corporation, SerializedCorporation } from '../../shared/models/corporation.model';
import { User, SerializedUser, NewUser, UserPage } from '../../shared/models/user.model';
import { Campus, SerializedCampus } from '../../shared/models/campus.model';
import { Dealership, SerializedDealership } from '../../shared/models/dealership.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = environment.apiUrl + '/users/';

  private refreshSubject = new BehaviorSubject<null>(null);
  public refresh$ = this.refreshSubject.asObservable();

  constructor(
    readonly http: HttpClient,
  ) { }

  getUsers(offset:number, limit:number): Observable<UserPage>  {
    let params = new HttpParams();
    params = params.append('offset', offset.toString()).append('limit', limit.toString());
    return this.http.get<UserPage>(this.apiUrl, { params })
      .pipe(
        map((page: UserPage) => {
          const users = page.items.map(i => User.deserialize(i))
          page.items = users;
          return page;
        }),
        catchError((error) => of(error))
      );
  }

  checkAuth(token?:string): Observable<User>  {
    let headers:HttpHeaders = new HttpHeaders();
    if (!isEmpty(token)) {
      headers = headers
        .set('Authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json')
        .set('X-Noauth', 'true');
    }
    return this.http.get<SerializedUser>(`${this.apiUrl}auth`, { headers })
      .pipe(
        map((user: SerializedUser) => User.deserialize(user)),
        catchError((error) => of(error))
      );
  }

  getMe(): Observable<User>  {
    return this.http.get<SerializedUser>(`${this.apiUrl}me`)
      .pipe(
        map((user: SerializedUser) => User.deserialize(user)),
        catchError((error) => of(error))
      );
  }

  createUser(newUser:NewUser): Observable<User>  {
    return this.http.post<User>(this.apiUrl, {
      email: newUser.email,
      first_name: newUser.firstName,
      last_name: newUser.lastName,
      role: newUser.role,
      campus_id: newUser.campusId,
      corporation_id: newUser.corporationId,
      dealership_id: newUser.dealershipId
    })
      .pipe(
        map((user: SerializedUser) => User.deserialize(user)),
        catchError((error) => of(error))
      );
  }

  getCorporations(name:string): Observable<Array<Corporation>> {
    let params = new HttpParams();
    params = params.append('name', name);
    return this.http.get<Array<Corporation>>(`${this.apiUrl}corporation`, { params })
      .pipe(
        map((corporations: Array<SerializedCorporation>) => corporations.map(c => Corporation.deserialize(c))),
        catchError((error) => of(error))
      );
  }

  getCampuses(name:string, corporationId:number): Observable<Array<Campus>> {
    let params = new HttpParams();
    params = params.append('name', name).append('corporation_id', corporationId);
    return this.http.get<Array<Campus>>(`${this.apiUrl}campus`, { params })
      .pipe(
        map((campuses: Array<SerializedCampus>) => campuses.map(c => Campus.deserialize(c))),
        catchError((error) => of(error))
      );
  }

  getDealerships(name:string, campusId:number): Observable<Array<Dealership>> {
    let params = new HttpParams();
    params = params.append('name', name).append('campus_id', campusId);
    return this.http.get<Array<Dealership>>(`${this.apiUrl}dealership`, { params })
      .pipe(
        map((dealerships: Array<SerializedDealership>) => dealerships.map(c => Dealership.deserialize(c))),
        catchError((error) => of(error))
      );
  }

  setActive(userId:number, active:boolean): Observable<User>  {
    return this.http.post<User>(`${this.apiUrl}active`, {
      user_id: userId,
      active,
    })
      .pipe(
        map((user: SerializedUser) => User.deserialize(user)),
        catchError((error) => of(error))
      );
  }

}
