
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { User } from '../shared/models/user.model';
import * as fromApp from '../store';
import { selectAuthState } from '../store/auth/auth.selectors';
import { localStorageKey } from '../store/storage.metareducer';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string | null = null;
  exp: number | null = null;
  actAsUser: User | null = null;

  constructor(
    readonly http: HttpClient,
    readonly store: Store<fromApp.AppState>,
  ) {
    this.store.select(selectAuthState)
      .subscribe((authState) => {
        if (this.token !== authState.token) {
          this.token = authState.token;
          this.exp = authState.exp;
        }
      });
  }

  // tslint:disable-next-line
  getAppStorage() {
    const appStorage = localStorage.getItem(localStorageKey);
    return JSON.parse(appStorage || '{}');
  }

  getToken(): string {
    if (!isEmpty(this.token)) {
      return this.token || '';
    }
    const appStorage = this.getAppStorage();
    if (appStorage) {
      return appStorage.auth.token;
    }
    return '';
  }

  getExp(): number {
    if (this.exp) {
      return this.exp;
    }
    const appStorage = this.getAppStorage();
    if (appStorage) {
      return appStorage.auth.exp;
    }
    return 0;
  }
}
