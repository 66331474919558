import { startCase } from 'lodash';

import { Base } from './base.model';
import { Corporation, DeserializedCorporation, SerializedCorporation } from './corporation.model';
import { Campus, DeserializedCampus, SerializedCampus } from './campus.model';
import { Dealership, DeserializedDealership, SerializedDealership } from './dealership.model';

export interface SerializedRole {
  id?: number;
  name?: string;
  corporation_id?: number;
  corporation?: SerializedCorporation;
  campus_id?: number;
  campus?: SerializedCampus;
  dealership_id?: number;
  dealership?: SerializedDealership;
}

export interface DeserializedRole {
  id?: number;
  name?: string;
  corporationId?: number;
  corporation?: DeserializedCorporation;
  campusId?: number;
  campus?: DeserializedCampus;
  dealershipId?: number;
  dealership?: DeserializedDealership;
}

export class Role extends Base {

  public id?: number;
  public name?: string;
  public corporationId?: number;
  public corporation?: Corporation;
  public campusId?: number;
  public campus?: Campus;
  public dealershipId?: number;
  public dealership?: Dealership;

  constructor(data?: DeserializedRole) {
    super();

    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.corporationId = data.corporationId;
      this.corporation = data.corporation ? new Corporation(data.corporation) : undefined;
      this.campusId = data.campusId;
      this.campus = data.campus ? new Campus(data.campus) : undefined;
      this.dealershipId = data.dealershipId;
      this.dealership = data.dealership ? new Dealership(data.dealership) : undefined;
    }
  }

  static deserialize(role: SerializedRole): Role {
    return new Role({
      ...role,
      name: startCase(role.name),
      corporationId: role.corporation_id,
      corporation: role.corporation ? Corporation.deserialize(role.corporation) : undefined,
      campusId: role.campus_id,
      campus: role.campus ? Campus.deserialize(role.campus) : undefined,
      dealershipId: role.dealership_id,
      dealership: role.dealership ? Dealership.deserialize(role.dealership) : undefined,
    });
  }

  serialize(): SerializedRole {
    return {
      id: this.id,
      name: this.name?.toLocaleLowerCase(),
      corporation_id: this.corporationId,
      corporation: this.corporation ? this.corporation.serialize() : undefined,
      campus_id: this.campusId,
      campus: this.campus ? this.campus.serialize() : undefined,
      dealership_id: this.dealershipId,
      dealership: this.dealership ? this.dealership.serialize() : undefined,
    };
  }
}