import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { NewUser, User, UserPage } from '../../shared/models/user.model';

export const getUsers = createAction(
  'User - Get Users',
  props <{offset: number, limit: number}>(),
);

export const getUsersSuccess = createAction(
  'User - Get Users Success',
  props <{data: UserPage}>(),
);

export const getUsersFailure = createAction(
  'User - Get Users Failure',
  props <{error: HttpErrorResponse}>(),
);
