import { Base } from './base.model';
import { DeserializedRole, Role, SerializedRole } from './role.model';

export interface UserPage {
  items: Array<User>;
  page: number;
  size: number;
  total: number;
}

export interface NewUser {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  corporationId?: number;
  campusId?: number;
  dealershipId?: number;
}

export interface SerializedUser {
  id?: number;
  email?: string;
  cognito_subject?: string;
  first_name?: string;
  last_name?: string;
  active?: boolean;
  role_id?: number;
  role?: SerializedRole;
}

export interface DeserializedUser {
  id?: number;
  email?: string;
  cognitoSubject?: string;
  firstName?: string;
  lastName?: string;
  active?: boolean;
  roleId?: number;
  role?: DeserializedRole;
}

export class User extends Base {

  public id?: number;
  public email?: string;
  public cognitoSubject?: string;
  public firstName?: string;
  public lastName?: string;
  public active?: boolean;
  public roleId?: number;
  public role?: Role;


  constructor(data?: DeserializedUser) {
    super();

    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.cognitoSubject = data.cognitoSubject;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.active = data.active;
      this.roleId = data.roleId;
      this.role = data.role ? new Role(data.role) : undefined;
    }
  }

  static deserialize(user: SerializedUser): User {
    return new User({
      ...user,
      cognitoSubject: user.cognito_subject,
      firstName: user.first_name,
      lastName: user.last_name,
      roleId: user.role_id,
      role: user.role ? Role.deserialize(user.role) : undefined,
    });
  }

  serialize(): SerializedUser {
    return {
      id: this.id,
      email: this.email,
      cognito_subject: this.cognitoSubject,
      first_name: this.firstName,
      last_name: this.lastName,
      active: this.active,
      role_id: this.roleId,
      role: this.role ? this.role.serialize() : undefined,
    };
  }

  getDisplayName({
    firstOnly = false,
    reverse = false,
    emailOnly = false,
    initials = false,
    includeEmail = false
  } = {}): string {
    if (this.email) {
      if (emailOnly || this.firstName == null) {
        return initials ? this.email.substring(0, 2).toUpperCase() : this.email;
      } else {
        let name = null;
        if (firstOnly || this.lastName == null) {
          name = initials ? this.firstName.substring(0, 2).toUpperCase() : this.firstName;
        } else {
          if (initials) {
            name = `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
          } else {
            name = reverse ? `${this.lastName}, ${this.firstName}` : `${this.firstName} ${this.lastName}`;
          }
        }
        return includeEmail ? `${name} <${this.email}>` : name;
      }
    }
    return '';
  }

  get isAdmin(): boolean {
    if (this.role && this.role.name && this.role.name.toLowerCase() === 'super') {
      return true;
    }
    return false;
  }
}
