<form [formGroup]="newUserForm">
  <div class="new-user-fields">
    <div class="p-field">
      <label for="email">Email</label>
      <input type="email" id="email" formControlName="email" placeholder="Email" pInputText email required />
    </div>
    <div class="p-field">
      <label for="firstName">First Name</label>
      <input type="text" id="firstName" formControlName="firstName" placeholder="First Name" pInputText required />
    </div>
    <div class="p-field">
      <label for="lastName">Last Name</label>
      <input type="text" id="lastName" formControlName="lastName" placeholder="Last Name" pInputText required />
    </div>
    <div class="p-field">
      <label for="role">Role</label>
      <p-dropdown id="role" appendTo="body" [options]="roles" placeholder="Select a Role" (onChange)="onRoleChange($event)"></p-dropdown>
    </div>
    <div class="p-field" *ngIf="!hideControls">
      <label for="corporation">Corporation</label>
      <p-autoComplete
        id="corporation"
        appendTo="body"
        [suggestions]="corporationSuggestions"
        placeholder="Select a Corporation"
        [showEmptyMessage]="true"
        emptyMessage="No Corporations found"
        (completeMethod)="searchCorporations($event)"
        (onSelect)="onCorporationChange($event)"
        (onClear)="onCorporationCleared()"
        (onKeyUp)="onCorporationKeyUp()"
      ></p-autoComplete>
    </div>
    <div class="p-field" *ngIf="!hideControls">
      <label for="campus">Campus</label>
      <p-autoComplete
        id="campus"
        appendTo="body"
        [suggestions]="campusSuggestions"
        placeholder="Select a Campus"
        [showEmptyMessage]="true"
        emptyMessage="No Campuses found"
        (completeMethod)="searchCampuses($event)"
        (onSelect)="onCampusChange($event)"
        (onClear)="onCampusCleared()"
        (onKeyUp)="onCampusKeyUp()"
        [disabled]="campusDisabled"
      ></p-autoComplete>
    </div>
    <div class="p-field" *ngIf="!hideControls">
      <label for="dealership">Dealership</label>
      <p-autoComplete
        id="dealership"
        appendTo="body"
        [suggestions]="dealershipSuggestions"
        placeholder="Select a Dealership"
        [showEmptyMessage]="true"
        emptyMessage="No Dealerships found"
        (completeMethod)="searchDealerships($event)"
        (onSelect)="onDealershipChange($event)"
        (onClear)="onDealershipCleared()"
        (onKeyUp)="onDealershipKeyUp()"
        [disabled]="dealershipDisabled"
      ></p-autoComplete>
    </div>
  </div>
</form>
<div class="p-dialog-footer">
  <p-button type="button" (click)="close(true)" [disabled]="newUserForm.invalid || !hasRequired">Save</p-button>
  <p-button type="button" (click)="close(false)" styleClass="p-button-secondary">Cancel</p-button>
</div>
