import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { setToken } from '../store/auth/auth.actions';

/**
 * Add token to all HttpClient request headers
 */
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService = this.injector.get(AuthService);

  constructor(readonly injector: Injector) {}

  setRequestHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!isEmpty(token)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    let token = this.authService.getToken();
    // if we're not doing authentication then just continue
    if (Boolean(request.headers.get('X-Noauth'))) {
      return next.handle(request);
    } else if (moment.unix(this.authService.getExp()).isBefore(moment())) {
      // the token has expired so refresh it
      return from(Auth.currentSession())
        .pipe(
          mergeMap((session:CognitoUserSession) => {
            return from(Auth.currentAuthenticatedUser())
              .pipe(
                mergeMap((user:CognitoUser) => {
                  return from(new Promise((resolve, reject) => {
                    user.refreshSession(session.getRefreshToken(), (e:any, t:any) => {
                      if (e) reject(e);
                      else resolve(t);
                    })
                  }))
                  .pipe(
                    mergeMap((data:any) => {
                      const store = this.injector.get(Store);
                      token = data.idToken.jwtToken;
                      this.authService.token = token
                      this.authService.exp = data.idToken.payload.exp;
                      store.dispatch(setToken({ idToken: token, exp: this.authService.exp } ));
                      return next.handle(this.setRequestHeaders(request, token));
                    }),
                    catchError(error => {
                      console.log(`There was a refreshing the session token: ${error}`);
                      return next.handle(this.setRequestHeaders(request, token));
                    })
                  );
                }),
                catchError(error => {
                  console.log(`There was a problem getting the current authenticate user: ${error}`);
                  return next.handle(this.setRequestHeaders(request, token));
                })
              );
          }),
          catchError(error => {
            console.log(`There was a problem getting the current user auth session: ${error}`);
            return next.handle(this.setRequestHeaders(request, token));
          })
        );
    } else {
      return next.handle(this.setRequestHeaders(request, token));
    }
  }
}
