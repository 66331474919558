import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Auth } from 'aws-amplify';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';

import * as AuthActions from './auth.actions';
import { selectAuthState } from './auth.selectors';
import { AppState } from '../index';
import { UserService } from '../../core/service/user.service';

@Injectable()
export class AuthEffects {

  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly userService: UserService,
    readonly store: Store<AppState>
  ) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      withLatestFrom(this.store.select(selectAuthState)),
      switchMap((state) => {
        if (state && state.length > 1 && state[1].currentUser) {
          return of(state[1].currentUser);
        } else {
          return this.userService.getMe();
        }
      }),
      switchMap((me) => {
        return of(AuthActions.loginSuccess({ user: me }));
      }),
      catchError(error => {
        return of(AuthActions.loginFailure({ error }));
      })
    );
  });

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap(action => {
        const url = `/${action.user.isAdmin ? 'admin' : 'dashboard'}`;
        this.router.navigateByUrl(url, { replaceUrl: true });
      })
    ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        Auth.signOut();
        this.router.navigateByUrl('/login', { replaceUrl: true });
        return of(AuthActions.clearAuthData())
      })
    )
  );
}
